<template>
  <Details
    title="application"
    resource="applications"
    :base-path="basePath"
    data-provider="$raaDataProvider"
    route-id-param="code"
    title-template="edit application {name}"
  >
    <template v-slot:side-panel="panelProps">
      <DetailsSidePanel>
        <template v-slot:heading>application details</template>
        <template v-slot:text>Edit the basic information about the {{panelProps.record.name}}.</template>
      </DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <ApplicationForm
        :initialValues="detailsProps.record"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import Details from "@/components/auth/details/Details";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import ApplicationForm from "@/views/auth/raa-devtools/applications/ApplicationForm";

  export default {
    name: "ApplicationEdit",
    components: {
      ApplicationForm,
      Details,
      DetailsSidePanel,
    },
    mixins: [ModalNavigation, ValidatorMixin],
    computed: {
      basePath: function () {
        return this.$route.name.replace('edit', 'index');
      },
      appCode: function () {
        return this.$route.params.code;
      },
    },
  }
</script>

<style scoped>

</style>
